import { Vue, setup } from "vue-class-component";
import { Backup, backupBloc } from "../services";
import { widget } from "@cems-eco/core/src/utils/widget";
import moment from 'moment';
import environment from "@/config";
import storage from "@/storage";

export default class ActivityPage extends Vue {

    activities: object[] = []
    options = {}
    events: object[] = []
    dt: any;
    isSearching = true;
    activity = new Backup;

    userOptions: any = {};

    created() {
    }

    mounted() {
        this.builDataTable();
    }

    async builDataTable() {
        let headers: any = {
            responseType: "json",
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        };

        let auth = storage.getSync<any>(storage.AUTH);
        if (auth) {
            headers.Authorization = `Bearer ${auth["access_token"]}`;
        }
        const loader: any = await widget.loading();
        loader.showLoading();
        this.options = {
            serverSide: true,
            searching: !this.isSearching,
            ajax: {
                url: backupBloc.base_url,
                method: 'GET',
                dataType: 'json',
                dataFilter: (data) => {
                    const parsedData = JSON.parse(data);
                    return JSON.stringify(parsedData.data);
                },
                headers,
            },
            mobileView: {
                header: {
                    title: function (data: any) {
                        return data.site.name;
                    },
                }
            },
            columns: [
                {
                    data: 'site.name',
                    title: 'Site',
                    mobileView: false,
                },
                {
                    data: 'type',
                    mobileView: true,
                    title: 'Type',
                },
                {
                    data: 'name',
                    mobileView: true,
                    title: 'File Name'
                },
                {
                    data: 'backup_at',
                    mobileView: true,
                    title: 'Backup At',
                    render: function (data, type, row) {
                        return moment(data).format('DD/MM/YYYY h:mm:ss');
                    }
                },
            ]
        };
        loader.close();
    }

    dtInstance(dt) {
        this.dt = dt;
    }
}

