import { Vue, setup } from 'vue-class-component';
import { AlarmRules, Sensor, sensorFactory } from "../services";
import { widget } from "@cems-eco/core/src/utils/widget";
import environment from '@/config';
import { Parser } from 'expr-eval';
import storage from '@/storage';

export default class CreateSensorPage extends Vue {
    sensorBloc = setup(() => sensorFactory())
    sensor = new Sensor
    clientOptions: any = {
        placeholder: '',
        allowClear: true,
        data: [],
    };
    siteOptions: any = {
        placeholder: '',
        allowClear: true,
        disabled: true,
        data: [],
    };
    equipmentOptions: any = {
        placeholder: '',
        allowClear: true,
        disabled: true,
        data: [],
    };
    unitOptions: any = {
        placeholder: '',
        allowClear: true,
        data: [],
    };
    ruleTypeOptions: any = [];
    ruleTagOptions: any = [];
    popupOptions: any = [];
    defaultRuleTagOptions: any = {
        placeholder: '',
        allowClear: true,
        data: [
            {
                id: 0,
                text: 'Normal'
            },
            {
                id: 1,
                text: 'Warning'
            },
            {
                id: 2,
                text: 'Critical'
            }
        ]
    };
    defaultRuleTypeOptions: any = {
        placeholder: '',
        allowClear: true,
        data: [
            {
                id: 0,
                text: 'Less Than'
            },
            {
                id: 1,
                text: 'More Than'
            },
            {
                id: 2,
                text: 'Range'
            }
        ]
    };
    defaultPopupOptions: any = {
        placeholder: '',
        allowClear: true,
        data: [
            {
                id: 0,
                text: 'Yes'
            },
            {
                id: 1,
                text: 'No'
            }
        ]
    };
    range: any[] = [];

    formulaResult: any = 0;
    formulaInput: any = "";
    formulaError: any = "";
    ruleResult: any = "";
    ruleInput: any = "";
    ruleError: any = "";
    movedIndex: number = -1;

    mounted() {
        this.sensor.formula = "n";
    }

    initOption(endpoint: string) {
        let headers: any = {
            responseType: "json",
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        };

        let auth = storage.getSync<any>(storage.AUTH);
        if (auth) {
            headers.Authorization = `Bearer ${auth["access_token"]}`;
        }

        return {
            placeholder: '-- Please select --',
            allowClear: true,
            ajax: {
                url: endpoint,
                headers,
                processResults: function (data) {
                    return {
                        results: data.data
                    };
                },
            },
            data: []
        }
    }

    async created() {
        const loader: any = await widget.loading();
        loader.showLoading();
        this.sensor.equipment_id = this.$route.params.id as string;

        this.clientOptions = this.initOption(environment.API_URL + '/client/select2');
        this.siteOptions = { disabled: true, ...this.initOption(environment.API_URL + '/site/select2') };
        this.equipmentOptions = { disabled: true, ...this.initOption(environment.API_URL + '/equipment/select2') };
        this.unitOptions = { ...this.initOption(environment.API_URL + '/reference/select2/sensor-unit') };
        if (this.sensor.equipment_id) {
            await this.getEquipment();
            await this.getSite();
            await this.getClient();
        }

        await this.duplicate();
        loader.close();
    }

    async duplicate() {
      
      if ("duplicate" in this.$route.query) {
        this.sensor.id = this.$route.query.duplicate
        this.sensor = await this.sensorBloc.getSensor(this.sensor.id);
        this.instanceRuleTypeOptions(this.sensor);
        this.instanceRuleTagOptions(this.sensor);
        this.instanceAlarmPopupOptions(this.sensor);
        this.getClient();
        this.getSite();
        this.getEquipment();
        this.getUnit();
        this.getRuleType();
        this.getRuleTag();
        this.getAlarmPopup();
        this.sensor.id = "";
      }
  
    }
    getAlarmPopup() {
        this.sensor.alarm_rules.forEach((row: any, index) => {
            const data = this.popupOptions[index].data.map((rule_type: any) => {
                const rule = { ...rule_type };
                rule.selected = rule.text == row.alarm_popup;
                return rule;
            });
            this.updateAlarmPopupOption(data, index);
        })
    }

    updateAlarmPopupOption(data: any[], index: number) {
        this.popupOptions[index] = {
            ...this.popupOptions[index],
            data: data,
        }
    }

    getRuleTag() {
        this.sensor.alarm_rules.forEach((row: any, index) => {
            const data = this.ruleTagOptions[index].data.map((rule_type: any) => {
                const rule = { ...rule_type };
                rule.selected = rule.text == row.rule_tag;
                return rule;
            });
            this.updateRuleTagOption(data, index);
        })
    }

    updateRuleTagOption(data: any[], index: number) {
        this.ruleTagOptions[index] = {
            ...this.ruleTagOptions[index],
            data: data,
        }
    }

    getRuleType() {
        this.sensor.alarm_rules.forEach((row: any, index) => {
            const data = this.ruleTypeOptions[index].data.map((rule_type: any) => {
                const rule = { ...rule_type };
                rule.selected = rule.text == row.rule_type;
                return rule;
            });
            this.updateRuleTypeOption(data, index);
        })
    }

    updateRuleTypeOption(data: any[], index: number) {
        this.ruleTypeOptions[index] = {
            ...this.ruleTypeOptions[index],
            data: data,
        }
    }

    getUnit() {
        if (this.sensor.unit) this.updateUnitOption([{ id: this.sensor.unit, selected: true, text: this.sensor.unit }]);
    }
    updateUnitOption(data: any[]) {
        this.unitOptions = {
            ...this.unitOptions,
            data: data,
        }
    }

    instanceAlarmPopupOptions(sensor: Sensor) {
        if (!sensor.hasOwnProperty("alarm_rules")) return;
        if (!sensor.alarm_rules) return;
        if (sensor.alarm_rules.length < 1) return;

        for (let index = 0; index < sensor.alarm_rules.length; index++) {
            this.popupOptions.push(this.defaultPopupOptions);
        }
    }

    instanceRuleTypeOptions(sensor: Sensor) {
        if (!sensor.hasOwnProperty("alarm_rules")) return;
        if (!sensor.alarm_rules) return;
        if (sensor.alarm_rules.length < 1) return;
        console.log(sensor.alarm_rules);
        for (let index = 0; index < sensor.alarm_rules.length; index++) {
            this.ruleTypeOptions.push(this.defaultRuleTypeOptions);
        }
    }

    instanceRuleTagOptions(sensor: Sensor) {
        if (!sensor.hasOwnProperty("alarm_rules")) return;
        if (!sensor.alarm_rules) return;
        if (sensor.alarm_rules.length < 1) return;

        for (let index = 0; index < sensor.alarm_rules.length; index++) {
            this.ruleTagOptions.push(this.defaultRuleTagOptions);
        }
    }

    async getEquipment() {
        const equipment = await this.sensorBloc.getEquipment(this.sensor.equipment_id);
        this.sensor.site_id = equipment.site_id;

        if (equipment) this.updateEquipmentOption([{ ...equipment, selected: true, text: equipment.name }]);
    }

    async getSite() {
        const site = await this.sensorBloc.getSite(this.sensor.site_id);
        this.sensor.client_id = site.client_id;

        if (site) this.updateSiteOption([{ ...site, selected: true, text: site.name }]);
    }

    async getClient() {
        const client = await this.sensorBloc.getClient(this.sensor.client_id);

        if (client) this.updateClientOption([{ ...client, selected: true, text: client.name }]);
    }

    async save() {
        try {
            this.sensor = await this.sensorBloc.storeRelation(this.sensor);
            widget.alertSuccess('Good Job!', 'You have successfully created a Sensor');
            this.$router.push(`/sensor/${this.sensor.id}`);
        } catch (error) {
            widget.alertError(error);
        }
    }

    updateClientOption(data: any[]) {
        this.clientOptions = {
            ...this.clientOptions,
            data: data,
            disabled: data.length == 0,
        }
    }

    updateSiteOption(data: any[]) {
        this.siteOptions = {
            ...this.siteOptions,
            data: data,
            disabled: data.length == 0,
        }
    }

    updateEquipmentOption(data: any[]) {
        this.equipmentOptions = {
            ...this.equipmentOptions,
            data: data,
            disabled: data.length == 0,
        }
    }

    clientSelectChanged(data: any[]) {
        this.sensor.client_id = data[0].id;
        const isDisabled = this.sensor.client_id == undefined || this.sensor.client_id == '';
        this.siteOptions.disabled = isDisabled;
        if (!this.equipmentOptions.disabled) {
            this.equipmentOptions.disabled = isDisabled;
            this.equipmentOptions.data = [];
        }
        if (isDisabled) this.siteOptions.data = []
        this.siteOptions.ajax.url = environment.API_URL + '/site/select2/client/' + this.sensor.client_id;
    }

    siteSelectChanged(data: any[]) {
        this.sensor.site_id = data[0].id;
        const isDisabled = this.sensor.site_id == undefined || this.sensor.site_id == '';
        this.equipmentOptions.disabled = isDisabled;
        if (isDisabled) this.equipmentOptions.data = []
        this.equipmentOptions.ajax.url = environment.API_URL + '/equipment/select2/site/' + this.sensor.site_id;

    }

    equipmentSelectChanged(data: any[]) {
        this.sensor.equipment_id = data[0].id;
    }

    unitSelectChanged(data: any[]) {
        this.sensor.unit = data[0].text;
    }

    ruleTypeSelectChanged(data: any[]) {
        let index: any = data[0].element.parentElement.getAttribute("data-index")
        this.sensor.alarm_rules[index].rule_type = data[0].text;
        if (this.sensor.alarm_rules[index].rule_type == "Range") {
            this.range[index] = true;
        } else {
            this.range[index] = false;
        }
    }

    ruleTagSelectChanged(data: any[]) {
        let index: any = data[0].element.parentElement.getAttribute("data-index")
        this.sensor.alarm_rules[index].rule_tag = data[0].text;
    }

    popupSelectChanged(data: any[]) {
        let index: any = data[0].element.parentElement.getAttribute("data-index")
        this.sensor.alarm_rules[index].alarm_popup = data[0].text;
    }

    addRow(e: any) {
        e.preventDefault();
        if (!this.sensor.alarm_rules) {
            this.sensor.alarm_rules = [];
        }
        this.ruleTypeOptions.push(this.defaultRuleTypeOptions);
        this.ruleTagOptions.push(this.defaultRuleTagOptions);
        this.popupOptions.push(this.defaultPopupOptions);
        this.sensor.alarm_rules.push(new AlarmRules);
    }

    removeRow(index: number, e: any) {
        e.preventDefault();
        this.sensor.alarm_rules.splice(index, 1);
    }

    calculateFormula(value, formula) {
        this.formulaResult = 0;
        if (!value) return this.formulaError = "Missing value";
        if (!formula) return this.formulaError = "Missing Formula";
        if (!formula.includes("n")) return this.formulaError = "Formula missing sensor variable 'n'";
        this.formulaError = "";
        this.formulaResult = Parser.evaluate(formula, { n: value });
    }

    testRule(value) {
        if (!this.sensor.alarm_rules) return this.ruleResult = `No Alarm Rule`;
        if (!value) return this.formulaError = "Missing value";

        value = parseFloat(value);
        let condition: boolean = false;
        for (let index = 0; index < this.sensor.alarm_rules.length; index++) {
            const row: AlarmRules = this.sensor.alarm_rules[index];
            switch (row.rule_type) {
                case 'Less Than':
                    condition = value < row.reading_value_from;
                    break;
                case 'More Than':
                    condition = value > row.reading_value_from;
                    break;
                case 'Range':
                    condition = value >= row.reading_value_from && value <= row.reading_value_to;
                    break;
            }

            if (condition) {
                const priority = (index + 1).toString().substr(index.toString().length - 1);
                let numberText = '';
                switch (priority) {
                    case '1':
                        numberText = 'st';
                        break;
                    case '2':
                        numberText = 'nd';
                        break;
                    case '3':
                        numberText = 'rd';
                        break;
                    default:
                        numberText = 'th';
                        break;

                }
                this.ruleResult = `${index + 1}${numberText} Alarm Rule (Alarm Rule Tag: ${row.rule_tag}) (Trigger Alarm Popup: ${row.alarm_popup})`;
                return;
            };
        }

        this.ruleResult = `No Alarm Rule`;
    }

    arrayMove(old_index, new_index) {
        const data = [this.ruleTypeOptions, this.ruleTagOptions, this.popupOptions, this.sensor.alarm_rules];
        data.forEach((arr: any) => {
            if (new_index >= arr.length) {
                var k = new_index - arr.length + 1;
                while (k--) {
                    arr.push(undefined);
                }
            }
            arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
        })
        this.movedIndex = new_index;
        const scope = this;
        setTimeout(() => {
            const el: any = document.getElementById('alamrrule-' + new_index);
            el.scrollIntoView({ behavior: "smooth" });
        }, 200);
        setTimeout(() => {
            scope.movedIndex = -1;
        }, 3000);
    }
}

