import { Backup, backupApi } from "./index"
import environment from "@/config"
import { Service } from "typedi";
import Container from "typedi";

@Service()
class BackupBloc {
    module = '';
    get base_url() {
        return `${environment.API_URL}/backup`;
    } 

    async getBackups() : Promise<[Backup]> {
        let response = await backupApi.all()
        return response.data.data;
    }

    async getBackup(id: any): Promise<Backup> {
        let response = await backupApi.first(id)
        return response.data;
    }
}

export const backupBloc = Container.get(BackupBloc);


