export interface IBackup {
    readonly id: any;
    site_id: string
    activity: string
}

export class Backup {
    public id!: any;
    public site_id!: string;
	public type: string;
	public name: string;
	public path: string;
	public backup_at: string;
}

