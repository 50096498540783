import { api } from '@cems-eco/core/src/services/api';
import { Service } from "typedi";
import Container from "typedi";

@Service()
class BackupApi {
    private BASE_URL: string;

    
    constructor() {
        this.BASE_URL = "/backup";
    }

    async all(): Promise<any> {
        return await api.get<any>(this.BASE_URL, true);
    }

    async first(id: any): Promise<any> {
        return await api.get<any>(`${this.BASE_URL}/${id}`, true);
    }

    async searchParams(data: any): Promise<any> {
        return api.toSerialize(data);
    }

}

export const backupApi = Container.get(BackupApi);
